module.exports = {
  reviews: [
    {
      source: "New York Times",
      date: "01/08/2020",
      url:
        "https://www.nytimes.com/interactive/2020/01/08/books/new-books-international.html",
      score: ""
    },
    {
      source: "Cosmopolitan",
      date: "11/28/2019",
      url:
        "https://www.cosmopolitan.com/uk/entertainment/g30045900/best-new-books-bame-black-poc-authors-2020/?slide=32",
      score: ""
    },
    {
      source: "Kirkus reviews",
      date: "01/26/2020",
      url:
        "https://www.kirkusreviews.com/book-reviews/jason-diakite/a-drop-of-midnight/",
      score: ""
    },
    {
      source: "Amazon",
      date: "",
      url:
        "https://www.amazon.com/Drop-Midnight-Memoir-Jason-Diakité/dp/1542016703",
      score: "4.4/5"
    },
    {
      source: "Goodreads",
      date: "",
      url: "https://www.goodreads.com/book/show/46651497-a-drop-of-midnight",
      score: "4.27/5"
    }
  ]
};
