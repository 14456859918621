import React from "react";

function BookDropdown() {
  return (
    <div className="mx-2">
      <div className="py-4">
        <div className="dropdown inline-block relative text-left">
          <button className="bg-white text-pink-500 font-semibold py-2 px-4 rounded inline-flex items-center">
            <span className="mr-1">Buy the book</span>
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
            </svg>
          </button>
          <ul className="dropdown-menu absolute hidden text-pink-500 pt-1">
            <li className="">
              <a
                className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                href="https://www.amazon.com/Drop-Midnight-Memoir-Jason-Diakité/dp/1542016703"
              >
                English &#40;Amazon&#41;
              </a>
            </li>
            <li className="">
              <a
                className="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                href="https://www.adlibris.com/no/bok/en-drape-midnatt-9788248919513"
              >
                Norwegian &#40;Adlibris&#41;
              </a>
            </li>
            <li className="">
              <a
                className="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                href="https://www.akademibokhandeln.se/bok/en-droppe-midnatt/9789100167578/"
              >
                Swedish &#40;Akademibokhandeln&#41;
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default BookDropdown;
