import React from "react";

function ReviewsButton() {
  return (
    <div className="mx-2">
      <button className="bg-white text-pink-500 font-semibold py-2 px-4 rounded inline-flex items-center">
        <span className="mr-1">
          <a href="#reviews">View reviews</a>
        </span>
      </button>
    </div>
  );
}

export default ReviewsButton;
