import React from "react";
import PageHeader from "../components/PageHeader";
import Layout from "../components/layout";
import Switcher from "../components/Switcher";
import BookDropdown from "../components/BookDropdown";
import ReviewsButton from "../components/ReviewsButton";
import Reviews from "../components/Reviews";

import SEO from "../components/seo";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

function BookPage() {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "book" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO
        keywords={[`Jason`, `Timbuktu`, `Diakité`, `A Drop Of Midnight`]}
        title="The Book"
      />
      <div className="flex flex-col font-sans min-h-screen text-black bg-pink-500">
        <main className="flex flex-col md:justify-center max-w-5xl mx-auto px-4 py-8 md:p-8 w-full">
          <div className="">
            <PageHeader />
            <Switcher />
            <div className="text-center flex flex-row items-center justify-center">
              <BookDropdown />
              <ReviewsButton />
            </div>
            <div className="flex flex-col md:flex-row mt-12 items-center md:items-start">
              <div className="w-full md:w-1/3 lg:w-5/12 max-w-xs md:max-w-full ">
                <Img
                  fluid={data.allFile.edges[0].node.childImageSharp.fluid}
                  className="border-8 border-white"
                />
              </div>
              <div className="w-full md:w-2/3 lg:w-7/12 md:px-8 mt-8 md:mt-0 text-md lg:text-lg">
                <p>
                  Born to interracial American parents in Sweden, Jason Diakité
                  grew up between worlds—part Swedish, American, black, white,
                  Cherokee, Slovak, and German, riding a delicate cultural and
                  racial divide. It was a no- man’s-land that left him in
                  constant search of self. Even after his hip-hop career took
                  off, Jason fought to unify a complex system of family roots
                  that branched across continents, ethnicities, classes, colors,
                  and eras to find a sense of belonging.
                </p>
                <p>
                  In A Drop of Midnight, Jason draws on conversations with his
                  parents, personal experiences, long-lost letters, and
                  pilgrimages to South Carolina and New York to paint a vivid
                  picture of race, discrimination, family, and ambition. His
                  ancestors’ origins as slaves in the antebellum South, his
                  parents’ struggles as an interracial couple, and his own
                  world- expanding connection to hip-hop helped him fashion a
                  strong black identity in Sweden.
                </p>
                <p>
                  What unfolds in Jason’s remarkable voyage of discovery is a
                  complex and unflinching look at not only his own history but
                  also that of generations affected by the trauma of the African
                  diaspora, then and now.
                </p>
              </div>
            </div>

            <div className="flex flex-col mt-2 items-center">
              <div className="w-full order-last">
                <Img fluid={data.allFile.edges[1].node.childImageSharp.fluid} />
              </div>
              <div className="w-full mt-2 md:mt-0 text-md lg:text-lg">
                <h2 className="font-bold text-2xl mb-4">About the author</h2>
                <p>
                  Jason “Timbuktu” Diakité is one of Sweden’s most well known
                  hip-hop artists. Born in Lund to American parents—an African
                  American dad and a white mom—he has released eight solo albums
                  and numerous singles, the majority of which have reached gold
                  or platinum status. His accolades include eight Swedish Grammy
                  awards and four P3 Guld (Swedish radio) awards. He has
                  performed all over the world, from Africa to Svalbard, from
                  the Apollo in New York City to the Roxy in Los Angeles and at
                  the Polar Music Prize and Nobel Peace Prize award ceremonies.
                  Jason is the author of the critically acclaimed memoir A Drop
                  of Midnight, which has sold more than one hundred thousand
                  copies in Sweden and was adapted by the author into a stage
                  performance in 2017.
                </p>
              </div>
            </div>
            <div id="reviews" className="mt-8">
              <Reviews />
            </div>
          </div>
        </main>
      </div>
    </Layout>
  );
}

export default BookPage;
