import React from "react";

function ReviewsItem(data) {
  return (
    <div>
      <ul>
        <li>
          <a
            href={data.data.url}
            rel="noopener noreferrer"
            className="underline"
          >
            {data.data.source}
          </a>

          {data.data.score ? ` (${data.data.score})` : ""}
        </li>
      </ul>
    </div>
  );
}

export default ReviewsItem;
