import React from "react";
import reviewsData from "../data/reviewsData";
import ReviewsItem from "../components/ReviewsItem";

export default function Reviews() {
  return (
    <div>
      <h3 className="text-2xl font-bold my-4">Reviews</h3>
      {reviewsData.reviews.map((data, index) => {
        return <ReviewsItem data={data} key={{ index }} />;
      })}
    </div>
  );
}
